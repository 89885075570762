import React from 'react'
import {SimpleGrid, Grid, GridItem} from '@chakra-ui/react'
import { mypx, tc, xgap, col2, col3} from '../../constants/spacing'

export const Single = ({children}) => {
    return (
        <SimpleGrid justifyItems="center" columns={1} gap={xgap}>
            {children}
        </SimpleGrid>
    )
}


export const Starter = ({children}) => {
    return (
        <SimpleGrid columns={1} px={mypx} py={xgap} gap={xgap}>
            {children}
        </SimpleGrid>
    )
}

export const Double = ({children}) => {
    return (
        <SimpleGrid columns={col2} gap={xgap}>
            {children}
        </SimpleGrid>
    )
}

